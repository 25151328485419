/* Navbar */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.navbar-design{
  background: linear-gradient(to right, #f0e130, #ffcc00);
}

.navbar .badge {
  font-size: 0.7rem;
  padding: 0.3em 0.4em;
}

.navbar .form-control {
  border-radius: 0.25rem;
}

.sidebar-icon {
  font-size: 1.2rem;
}

/* Hide sidebar items on large screens */
@media (min-width: 992px) {
  .offcanvas {
    display: none;
  }
}
@media (max-width: 768px) {
  .navbar .d-lg-flex {
    display: none !important; /* Hides search bar and icons */
  }
}
.bhakshanagal-logo{
  width:90px !important;
  height:70px !important
}



/* Add space between the Nav links */
.sidebar .nav-link {
  margin-bottom: 25px; /* Adjust the value as needed */
}

.sidebar .nav-link:hover {
 background-color: #ffcc00;
 color:black !important
}

/* Or, if you want to use flex and add spacing, use this: */
.sidebar .nav {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adjust the gap between links */
}

