.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dashboard-heading {
  /* font-size: 2rem; */
  font-weight: 600;
  color: #333;
  /* letter-spacing: 1px; */
}

.dashboard-first {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px; /* Adjusted for better spacing */
  margin-top: 40px; /* Added spacing from the heading */
}
.column {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 8px; /* Soft corner edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added subtle shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.column:hover {
  transform: translateY(-5px); /* Slight hover effect */
}

.dashboard-details {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
}

.data-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-top: 10px;
}



.custom-table {
  width: 100%;
  border-collapse: collapse; 
  text-align: left; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Prevents overflow for rounded corners */
}

.custom-table th, .custom-table td {
  padding: 12px 15px; /* Increased padding for better spacing */
  font-size: 0.95rem; /* Slightly larger font for better readability */
  border-bottom: 1px solid #ddd; /* Subtle border between rows */
}

.custom-table th {
  background-color: #007bff; /* Bold header background color */
  color: white; /* White text for header */
  font-weight: 600; /* Bold text for header */
  text-transform: uppercase; /* Makes the header text uppercase */
  letter-spacing: 1px; /* Slightly more spacing between letters */
}

.custom-table td {
  background-color: #fff; /* White background for cells */
}

.custom-table tr:nth-child(even) td {
  background-color: 
  #D9EDBF; /* Alternating row colors for readability */
}

.custom-table tr:hover {
  background-color: #f1f1f1; /* Light hover effect */
  cursor: pointer; /* Indicates interactivity */
  transform: scale(1.01); /* Slight zoom effect for hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.custom-table tr:nth-child(odd) td {
  background-color: #ffffff; /* White rows for odd rows */
}

.custom-table .white-row {
  background-color: white;
}

.custom-table .yellow-row {
  background-color: #fff7e6; /* Soft yellow color for better readability */
}

.custom-table td, .custom-table th {
  padding: 10px;
  text-align: left;
}

.filter-button {
  background-color: #28a745 !important; /* Green background for filter button */
  border: none !important;
  padding: 8px 15px; /* Adjusted padding for a more balanced button */
  color: white;
  font-weight: 600;
  border-radius: 4px; /* Rounded corners for buttons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #218838 !important; /* Darker green on hover */
}

.filter-container {
  position: relative; 
  margin-bottom: 20px; /* Added margin to give space below filter */
}

.date-picker {
  font-size: 1rem; /* Adjusted font size */
  padding: 8px; /* Added padding for better usability */
  width: 150px !important; /* Increased width */
  border-radius: 5px; /* Rounded edges */
  border: 1px solid #ccc; /* Subtle border */
}

.filter-button,
.me-2 {
  font-size: 1rem; /* Adjusted button font size */
  padding: 8px 12px; /* Adjusted button padding */
  border-radius: 4px; /* Rounded corners for buttons */
}

.custom-table td, .custom-table th {
  padding: 15px; 
  text-align: left;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .container {
    grid-template-columns: 1fr; 
  }
}

.custom-table th {
  background-color: #000 !important; /* Black background */
  color: #fff !important; /* White text */
  font-weight: bold; /* Bold text */
  padding: 12px; /* Padding for better spacing */
  text-align: left; /* Align text to the left */
}

.custom-table td {
  padding: 10px; /* Padding for data cells */
  text-align: left; /* Left-align text */
}

/* Add space between rows */
.custom-table tbody tr {
  margin-bottom: 10px; /* Adds space between rows */
}

/* Optionally, you can also add padding inside the rows to make the content look more spacious */
.custom-table tbody tr td {
  padding: 10px; /* Adjust as needed */
}
.dashboard-image-banner{
  height:250px !important;
  width: 100% !important;
}
