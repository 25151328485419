/* Container for the entire form */
.add-product-form-container {
  width: 50%;
  margin: 50px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Style the form labels */
.add-product-form-label {
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
  display: block;
}

/* Inputs, select, and text area */
.add-product-input,
.add-product-textarea,
.add-product-select,
.add-product-file-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.add-product-input:focus,
.add-product-textarea:focus,
.add-product-select:focus,
.add-product-file-input:focus {
  border-color: #0066cc;
  outline: none;
}

/* Style the file input */
.add-product-file-input {
  padding: 8px 12px;
}

/* Select dropdown style */
.add-product-select {
  cursor: pointer;
}

/* Style the submit button */
.add-product-submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.add-product-submit-btn:hover {
  background-color: #0056b3;
}

/* Checkbox style */
.add-product-checkbox {
  margin-top: 8px;
}

/* Error or existing product image text */
.add-product-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Style the success/error messages */
.toast-container {
  z-index: 9999;
}
.add-product-button{
  background-color: green !important;
  padding:6px;
  font-size: 15px;
  border:none;
  border-radius: 10px !important;
}