.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dashboard-heading {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 1px;
}

.add-product-btn {
  border: none !important;
  color: white !important;
  background-color: green !important;
  width: 250px;
  padding: 10px;
}

/* Card styling */
.card-size-adjust {
  width: 100%; /* Ensure cards take full width available */
  max-width: 300px; /* Limit card width on larger screens */
  height: auto; /* Let height adjust automatically */
  margin: 0 auto; /* Center cards in their column */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image-container {
  position: relative;
}

.card-img-top {
  width: 100%;
  height: 250px !important;
  object-fit: cover; /* Maintain aspect ratio for images */
}

.icon-left,
.icon-right {
  position: absolute;
  top: 10px;
  font-size: 20px;
  color: grey;
  cursor: pointer;
  background-color: white; /* Optional: to ensure background behind the icons is white */
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.icon-left :hover,
.icon-right :hover {
  color: brown !important;
}

.icon-left {
  left: 10px;
}

.icon-right {
  right: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .add-product-btn {
    width: 100%; /* Full width button on smaller screens */
    padding: 15px;
  }

  .card-img-top {
    height: auto; /* Let image height adjust based on its width */
  }

  .card-size-adjust {
    max-width: 100%; /* Full-width cards on small screens */
    margin-bottom: 20px; /* Add spacing between cards */
  }

  .icon-left,
  .icon-right {
    font-size: 16px; /* Adjust icon size for smaller screens */
    top: 5px;
  }
}

@media (max-width: 576px) {
  .dashboard-heading {
    font-size: 1.5rem; /* Adjust font size for very small screens */
  }

  .card-img-top {
    height: auto; /* Ensure proper image scaling */
  }

  .icon-left,
  .icon-right {
    font-size: 14px; /* Make icons smaller for very small screens */
    top: 3px;
    padding: 8px;
  }

  .card-size-adjust {
    margin-bottom: 15px; /* Add tighter spacing between cards */
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.button-number {
  background-color: green !important;
  border: none !important;
  width: 120px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px; /* Add spacing between buttons */
}

@media (max-width: 576px) {
  .button-number {
    width: 100px;
    height: 35px;
    font-size: 0.8rem;
  }
}

.card-title {
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add dots for overflow text */
}

.card-text {
  font-size: 0.9rem; /* Adjust font size for price and quantity */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

